// 
// user-variables.scss
// Use this to override Bootstrap and Finder variables
//

// Example of a variable override to change Finder background color
// Remove the "//" to comment it in and see it in action!


 $primary:                     #4682B4		!default;
$secondary:                   #eff2fc !default;
$info:		                    #4c82f7 !default;
$success:                     #22c55e !default;
$warning:                     #ffba08 !default;
$danger:                      #ef4444 !default;
